@import "./variables";
@import "./mixins";

// note 320px is iPhone SE: least wide phone on the market
$min-width-modal: 32rem;
$max-width-modal: 60rem;

$max-width-small-modal: 40rem;

// Color of overlay background
$color-overlay: rgba(0, 0, 0, .66) !default;

// Transition time
// !! The same as the hideDelay variable defined in ngx-smart-modal.component.ts
$transition-duration: 200ms !default;

// Transition effect
// linear | ease | ease-in | ease-out | ease-in-out
$transition-timing-function: ease-in-out !default;

// Body overflow when a modal is opened.
// Set it to `auto` if you want to unlock the page scroll when a modal is opened
$opened-modal-body-overflow: hidden !default;

// Body if modal is opened
html.dialog-open, body.dialog-open {
  overflow: $opened-modal-body-overflow;
}

// Close button in modal
.nsm-dialog-btn-close {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.8rem;
  cursor: pointer;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color $transition-duration;
  background-color: transparent;
  z-index: 999;
  display: flex;

  &.nsm-overlay-open {
    background-color: $color-overlay;
  }

  &.transparent {
    background-color: transparent;
  }
}

// Dialog modal
.nsm-dialog {
  //position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  max-width: $max-width-modal;
  min-width: $min-width-modal;
  margin: auto;
  pointer-events: none;
  outline: none;
  text-align: center;

  &.full-height {
    margin: 0 auto;

    .nsm-content {
      height: 100%;
    }
  }

  // When dialog is closing
  &.nsm-dialog-close {
    opacity: 0;
  }

  &.nsm-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (2.8rem * 2));
  }
}

.include-padding {
  .nsm-content {
    padding: 3.2rem;
  }
}

.grey-background {
  .nsm-content {
    background-color: var(--almost_white);
  }
}

.nsm-dialog.small-modal {
  max-width: $max-width-small-modal;
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 1.2rem;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12);
  outline: 0;

  // For performance purpose
  transform: translate3d(0, 0, 0);

  &.include-padding {
    padding: 3.2rem;
  }

  .close-button {
    position: absolute;
    display: flex;
    right: 1.8rem;
    top: 1.8rem;
    cursor: pointer;
  }
}

.nsm-body {
  //position: relative;
  flex: 1 1 auto;

  .bottom-buttons {
    display: flex;
    margin-top: 3.2rem;
    width: 100%;
    justify-content: center;
  }
}

/* *************************
* Animations
* *************************/

.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform $transition-duration $transition-timing-function, opacity $transition-duration;
}

// Left to right (ltr)
.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(-50%, 0, 0);
  }
}

// Right to left (ltr)
.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(50%, 0, 0);
  }
}

// Top to bottom (ttb)
.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, -50%, 0);
  }
}

// Bottom to top (btt)
.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, 50%, 0);
  }
}
